class InvoiceValues {
  PM?: number
  ISS?: number
  DR?: number
  IOF?: number
}

class CarrierInvoiceValidation {
  validationId?: number
  competencyDate?: string
  carrier?: object
  financialGroup?: object
  subContractId?: string
  policy?: string
  benefitType?: string
  name?: string
  companyDocument?: string
  companyName?: string
  countRegistrySuccess?: number
  countValueSuccess?: number
  countRegistryError?: number
  countValueError?: number
  countRegistryNotFoundInvoice?: number
  countRegistryNotFoundPreview?: number
}

class CarrierInvoicePriceValidation {
  validationId?: number
  carrierInvoiceId?: number
  carrierInvoicePreviewId?: number
  competencyDate?: string
  carrier?: object
  financialGroup?: object
  subContractId?: string
  policy?: string
  benefitType?: string
  name?: string
  companyDocument?: string
  companyName?: string
  status?: string
  checked?: boolean
  invoiceTotalLives?: number
  invoiceTotalValue?: number
  previewTotalLives?: number
  previewTotalValue?: number
  invoiceValues?: InvoiceValues
  previewValues?: InvoiceValues
}

class CarrierInvoiceValidationDetail {
  validationId?: number
  validationDetailId?: number
  invoiceId?: number
  previewId?: number
  status?: string
  checked?: boolean
  carrier?: object
  financialGroup?: object
  subContractId?: string
  policy?: string
  companyDocument?: string
  companyName?: string
  competencyDate?: string
  invoiceDate?: string
  invoiceType?: string
  beneficiaryName?: string
  beneficiaryDocumentNumber?: string
  beneficiaryType?: string
  birthDate?: string
  familyCode?: string
  familyCodeSeq?: string
  planCode?: string
  planPrice?: string
}

export class FileTypes {
  active?: boolean
  azureContainerName?: any
  createdAt?: string
  id?: string
  name?: string
  tableContext?: string
}

class ValidationDetail {
  id?: number
  carrierInvoiceId?: number
  competencyDate?: string
  invoiceDate?: string
  invoiceType?: string
  insuredName?: string
  insuredDocumentNumber?: string
  premiumValue?: number
  benefitCode?: string
  planCode?: string
  beneficiaryType?: string
  birthDate?: string
  kinship?: string
  cardNumber?: string
  familyCode?: string
  familyCodeSeq?: string
  inclusionDate?: string
  exclusionDate?: any
  financialGroupId?: string
  carrierId?: string
  contractId?: string
  subContractId?: string
  subContractNumber?: string
  cnpj?: string
  createdAt?: string
  updatedAt?: any
}

class Carrier {
  id?: string;
  name?: string;
}

class FinancialGroup {
  id?: string;
  name?: string;
}

export class InvocieFile {
  fileId?: number;
  fileName?: string;
  complementFilesIds?: number[] | null;
  complementFilesNames?: string[] | null;
  competencyDate?: string;
  carrier?: Carrier;
  financialGroup?: FinancialGroup;
  contractId?: number;
  subContractId?: number;
  companyName?: string;
  policy?: string;
  subContractName?: string;
  companyDocument?: string;
}

export class OperationalRoutinesModel {
  carrierInvoiceValidations?: CarrierInvoiceValidation[];
  carrierInvoicePriceValidations?: CarrierInvoicePriceValidation[];
  carrierInvoiceValidationDetail?: CarrierInvoiceValidationDetail[];
  validationDetails?: ValidationDetail[];
  validationDetailCriticism?: string[];
  validationDetailComment?: string[];
  validationComment?: string[];
  carrierInvoiceTypes?: string[];
  selectedContracts?: CarrierInvoiceValidation[];
  cardValidationIds?: [];
  fileTypes?: FileTypes[];
  invoiceFiles?: InvocieFile[];

  constructor() {
    this.carrierInvoiceValidations = [];
    this.carrierInvoicePriceValidations = [];
    this.carrierInvoiceValidationDetail = [];
    this.validationDetails = [];
    this.validationDetailCriticism = [];
    this.validationDetailComment = [];
    this.validationComment = [];
    this.carrierInvoiceTypes = [];
    this.selectedContracts = [];
    this.cardValidationIds = [];
    this.fileTypes = [];
    this.invoiceFiles = [];
  }
}